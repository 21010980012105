import React, { useContext, useState, useEffect } from 'react';
import pt from 'prop-types';
import Image from '../../../ui/image';
import Text from '../../../ui/text';
import Link from '../../../ui/link';
import Icon from '../../../ui/icon';
import * as styles from './styles.module.scss';
import { MicrocopyContext } from '../../../../context/microcopy.context';
import { getCopy } from '../../../../helpers/copy';

import { getAPIRoot } from '../../../../helpers/fetch';

const EventsDetailContent = ({ image, description, link, id }) => {
  const microcopy = useContext(MicrocopyContext);
  const [apiRoot, setApiRoot] = useState('');

  useEffect(() => {
    const getApiRoot = async () => {
      const url = await getAPIRoot();
      setApiRoot(url);
    };

    getApiRoot();
  }, []);

  return (
    <>
      {image && <Image image={{ url: image }} />}
      {description && (
        <Text as="p" type="h3">
          {description}
        </Text>
      )}
      {link && (
        <div className={styles.link}>
          <Link link={{ externalLink: link }} asButton>
            {getCopy('label.visitWebsite', microcopy)}
          </Link>
        </div>
      )}
      <div>
        <Link
          link={{
            externalLink: `${apiRoot}${process.env.GATSBY_API_EVENT_ICS.replace(
              'eventId',
              id
            )}`,
          }}
          asButton
          btnType="secondary"
          download
        >
          <Text as="span" type="button">
            {getCopy('label.addToCalendar', microcopy)}
          </Text>
          <Icon name="calendar-alt" className={styles.icon} />
        </Link>
      </div>
    </>
  );
};

EventsDetailContent.propTypes = {
  image: pt.string,
  description: pt.string,
  link: pt.string,
  id: pt.number.isRequired,
};

export default EventsDetailContent;
